import React from "react";

import { EuiFlexGroup, EuiLoadingSpinner } from "@elastic/eui";

export const LoadingIndicator: React.FC = () => {
  return (
    <EuiFlexGroup justifyContent={"spaceAround"}>
      <EuiLoadingSpinner size={"xl"} />
    </EuiFlexGroup>
  );
};
